@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu-suspenso {
    @apply visible fixed top-[60px] z-[1000] flex h-full w-full max-w-[280px] flex-col items-center justify-between overflow-y-auto overflow-x-hidden bg-mglGray50 transition-[left] duration-300 ease-ease tablet:top-0 [&.showMenu]:flex [&.show]:block;
  }
  .list-navbar {
    @apply relative w-full cursor-pointer list-none px-[15px] py-6 text-sm leading-[40px] [&:not(.show)]:hidden;
  }

  .list-item-navbar-content {
    @apply hover:rounded hover:bg-black/5 [&_a]:block [&_a]:h-full [&_a]:px-4 [&_a]:py-0 [&_a]:text-[inherit] [&_a]:no-underline [&_svg]:mr-[9px] [&_svg]:align-middle [&_svg]:text-lg [&_svg]:text-[#bbb] [&_svg]:transition-colors [&_svg]:duration-300 [&_svg]:ease-ease [&_svg]:hover:text-[#555] [&_svg]:hover:transition-[fill] [&_svg]:hover:duration-300 [&_svg]:hover:ease-ease;
  }

  .navbar-divider {
    @apply before:mx-auto before:my-2 before:block before:h-[1px] before:w-[90%] before:bg-black/10 before:content-[""];
  }

  .container-live -webkit-scrollbar {
    @apply h-2 w-2;
  }

  .container-live -webkit-scrollbar-track {
    @apply rounded-[10px] bg-[rgba(0,0,0,0.4)];
  }

  .container-live -webkit-scrollbar-thumb {
    @apply rounded-[10px] bg-[#dfdfdf];
  }
}

@layer base {
  * {
    font-family: Lexend, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  body {
    -webkit-text-size-adjust: none;
    line-height: 1.5;
  }

  #content-news {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.2;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: 500;
  }

  label {
    line-height: 1;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  [disable] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
      local('Lexend'),
      url('https://canaltech.com.br/fonts/Lexend/Lexend-Light.woff2') format('woff2'),
      url('https://canaltech.com.br/fonts/Lexend/Lexend-Light.woff') format('woff'),
      url('https://canaltech.com.br/fonts/Lexend/Lexend-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src:
      local('Lexend'),
      url('https://canaltech.com.br/fonts/Lexend/Lexend-Medium.woff2') format('woff2'),
      url('https://canaltech.com.br/fonts/Lexend/Lexend-Medium.woff') format('woff'),
      url('https://canaltech.com.br/fonts/Lexend/Lexend-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: MagaluSubmarcas-Bold;
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src:
      url('https://ofertas.canaltech.com.br/fonts/MagaluSubmarcas-Bold.eot'),
      url('https://ofertas.canaltech.com.br/fonts/MagaluSubmarcas-Bold-web.ttf') format('truetype');
  }

  @font-face {
    font-family: MagaluSubmarcas-Light;
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src:
      url('https://ofertas.canaltech.com.br/fonts/MagaluSubmarcas-Light.eot'),
      url('https://ofertas.canaltech.com.br/fonts/MagaluSubmarcas-Light-web.ttf') format('truetype');
  }
}
