/* TODO: refatorar para styled-components */

.swiper-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.swiper-button-disabled {
  opacity: 0;
}

.swiper-pagination {
  text-align: right;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  text-align: right;
  line-height: 20px;
  font-size: 0.75rem;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  bottom: -2px;
}

.swiper-pagination-bullets {
  position: relative !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;
}

.container-swiper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  padding-bottom: 1em;
}

.container-swiper__prev {
  left: 0px;
}

.container-swiper__next {
  right: 0px;
}

.swiper-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  z-index: 5;
  background-color: #0086ff;
  color: #fff;
  cursor: pointer;
  border-radius: 25px;
  transition: opacity 0.3s, transform 0.3s;
}

.swiper-button {
  &:not(.swiper-button-disabled) {
    :hover {
      opacity: 0.8;
    }
  }
}

.swiper-pagination-bullet-active {
  background-color: #0086ff;
}

.tooltip-chart-container {
  padding: 8px;
}

.tooltip-chart-phrase {
  font-size: 0.7rem;
}

.tooltip-chart-price {
  font-weight: bold;
  color: #0086ff;
}
